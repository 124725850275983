@import "../../../reskin/reskin.css";

.active_set_link {
  color: var(--main) !important;
  font-weight: 600;
}

.active_set_link > span {
  border-color: var(--main) !important;
}

.settings_menu {
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 40px 60px 20px 60px;
  box-shadow: inset -30px 0 20px -20px rgba(0, 0, 0, 0.1);
}

.settings_menu > a {
  color: #2c3e50;
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 36px;
}

.settings_menu > a > span {
  width: 36px;
  height: 36px;
  border: 2px solid #2c3e50;
  border-radius: 50%;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.settings_content {
  width: calc(100% - 400px);
  padding: 20px 50px 80px;
  position: relative;
}

.settings_content::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50px;
  background: linear-gradient(
    90deg,
    #d7d7d7,
    hsla(0, 0%, 89%, 0.91) 9%,
    hsla(0, 0%, 100%, 0)
  );
}

.settings_content h3 {
  color: var(--main);
  font-size: 24px;
  font-weight: 500;
  margin-top: 40px;
  margin-bottom: 20px;
}

@media (max-width: 1400px) {
  .settings_menu {
    width: 320px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .settings_content {
    width: calc(100% - 320px);
  }

  .settings_menu > a {
    font-size: 18px;
  }
}

@media (max-width: 800px) {
  .settings_menu {
    width: 200px;
    padding: 20px;
  }

  .settings_menu > a {
    font-size: 14px;
  }

  .settings_menu > a > span {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }

  .settings_content {
    width: calc(100% - 200px);
  }
}

@media (max-width: 500px) {
  .settings_menu {
    width: 180px;
    padding: 20px;
  }

  .settings_content {
    width: calc(100% - 180px);
  }

  .help a {
    width: unset !important;
  }
}

@media (max-width: 400px) {
  .settings_menu {
    padding: 20px 5px;
    width: 140px;
  }

  .settings_menu > a {
    font-size: 12px;
  }

  .settings_menu > a > span {
    width: 25px;
    height: 25px;
    font-size: 14px;
    margin-right: 5px;
  }

  .settings_content {
    width: calc(100% - 140px);
  }
}

.lang_span {
  border: 0 !important;
}

.lang_span svg {
  width: 36px;
  height: 36px;
}

.active_set_link .lang_span svg path {
  fill: var(--main);
}
