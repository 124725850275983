@import "../../../reskin/reskin.css";

.internet_channels {
  min-height: calc(100vh - 175px);
}

.internet_channels_holder {
  height: calc(100vh - 235px);
  justify-content: center;
}

.top_labels {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  justify-content: space-between;
  padding: 20px 10px;
}

.top_labels h3 {
  color: #2c3e50;
  font-weight: 600;
}

.channels_menu.wide {
  width: 500px;
}

.channels_menu.wide .items {
  padding: 0;
}

.channels_menu.wide .item.movie {
  padding: 6px 10px;
  height: 75px;
  overflow: hidden;
}

.channels_menu.wide .item .left {
  /* width: 100px; */
  width: 65%;
  justify-content: space-between;
}

.channels_menu.wide .item .left .channel_info {
  display: flex;
  align-items: center;
}

.channels_menu.wide .item .left .channel_info img {
  width: 50px;
}

.channels_menu.wide .item .left p {
  font-size: 14px;
}

/* .channels_menu.wide .item .center {
  display: flex;
  flex: 1;
  justify-content: center;
} */

.channels_menu.wide .item .left .channel_icons {
  display: flex;
  align-items: center;
}

.channels_menu.wide .item .left svg {
  font-size: 24px;
  color: #333;
  margin-left: 12px;
}
.channels_menu.wide .channels_menu_holder {
  height: 90%;
}
.channels_menu.wide .item .left svg.lock {
  font-size: 20px;
}

.category_top {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.channels_menu.wide .input_holder {
  margin: 0;
  width: calc(100% - 8px);
}

.category_top .red {
  font-size: 28px;
  position: absolute;
  right: 10px;
  top: 2px;
  cursor: pointer;
}

.channels_menu.wide .item:hover {
  transform: none;
  background: #fff;
  box-shadow: none;
  -webkit-box-shadow: none;
  z-index: 10;
  color: #2c3e50;
}

.channels_menu.wide .item .right {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main);
  width: 35%;
  position: relative;
}

.channels_menu.wide .item .right .add_to_category_btn {
  /* padding-left: 30px; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 30px;
  /* position: relative; */
}

.channels_menu.wide .item .right svg {
  font-size: 28px;
}

.channels_menu.wide .item .right svg.red {
  /* margin-left: 22px; */
  position: absolute;
  right: 5px;
  top: 11px;
}

.isEmpty {
  width: calc(100% - 450px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #777;
}

.isEmpty svg {
  font-size: 60px;
}

.isEmpty p {
  font-size: 28px;
}

.no_added_internet_channels {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000;
}

.no_added_internet_channels svg {
  font-size: 70px;
  color: rgb(156, 156, 156);
  margin-bottom: 10px;
}

.no_added_internet_channels p {
  padding: 0 20px;
  font-size: 28px;
}

.no_added_internet_channels a {
  background: linear-gradient(var(--main), var(--secondaryGradient));
  color: #fff;
  border: none;
  padding: 5px 25px;
  margin-top: 10px;
  border-radius: 5px;
}

@media (max-width: 1500px) {
  .isEmpty {
    font-size: 26px;
  }

  .nochannel_wrap {
    font-size: 26px !important;
  }

  .isEmpty {
    width: calc(100% - 400px);
  }

  .no_added_internet_channels p {
    text-align: center;
  }
}

@media (max-width: 1150px) {
  .isEmpty {
    width: calc(100% - 350px);
  }
}

@media (max-width: 900px) {
  .isEmpty {
    width: 100%;
  }

  .no_added_internet_channels svg {
    font-size: 60px;
  }

  .no_added_internet_channels p {
    font-size: 24px;
  }

  .no_added_internet_channels span {
    font-size: 20px;
  }
}

.internet_channels .show_more {
  width: 100%;
  height: 50px;
  cursor: pointer;
  margin: 20px 0;
  border: 0;
  border-radius: 8px;
  background: linear-gradient(var(--main), var(--secondaryGradient));
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
}

.internet_channels .show_more:hover {
  opacity: 0.9;
}

.add_to_category {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 40px 0 40px;
}

.add_to_category .channelIcon {
  width: 120px;
}

.add_to_category .categories {
  overflow: scroll;
  padding-right: 80px;
}

.add_to_category .categories::-webkit-scrollbar {
  width: 7px;
  border-radius: 30px;
}

/* Track */
.add_to_category .categories::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.add_to_category .categories::-webkit-scrollbar-thumb {
  background: rgb(197, 197, 197);
  border-radius: 30px;
}

/* Handle on hover */
.add_to_category .categories::-webkit-scrollbar-thumb:hover {
  background: rgb(197, 197, 197);
}

.add_to_category .categories .single_category {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.add_to_category .categories .single_category img {
  width: 50px;
  margin-right: 20px;
  background: #2c3e50;
  margin-bottom: 5px;
  padding: 8px;
  border-radius: 50px;
}
.add_to_category .categories .single_category p.active {
  color: red;
}
