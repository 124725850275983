.favorites .title {
  padding: 20px 0;
  display: flex;
  align-items: center;
}

.favorites .todayEpgs_item_bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.favorites .todayEpgs_item_bottom img {
  width: 70px;
}

.nofavorites {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nofavorites p {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  font-size: 34px;
  color: #222;
  font-weight: lighter;
}

/* Radio Favorites */

.favorites_radios_wrap {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 140px;
  grid-gap: 50px 20px;
  padding-bottom: 50px;
}

.favorites_radios_wrap > .favorites_radios_item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0 0 12px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform: scale(0.95);
  border: 4px solid transparent;
}

.favorites_radios_wrap > .favorites_radios_item > img {
  width: 130px;
}

.favorites_radios_wrap > .favorites_radios_item > svg {
  position: absolute;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.favorites_radios_wrap > .favorites_radios_item > svg.radio_stations_item_zoom {
  right: 5px;
  color: #979ea1;
}

.favorites_radios_wrap
  > .favorites_radios_item
  > svg.radio_stations_item_zoom:hover {
  color: #555;
}

.favorites_radios_wrap > .favorites_radios_item:hover {
  transform: scale(1.1);
}

.favorites_radios_wrap > .favorites_radios_item:hover > svg {
  opacity: 1;
}

@media (max-width: 1550px) {
  .favorites_radios_wrap {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 1300px) {
  .favorites_radios_wrap {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 950px) {
  .favorites_radios_wrap {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 750px) {
  .favorites_radios_wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .favorites_radios_wrap {
    grid-template-columns: 100%;
    grid-auto-rows: 140px;
    justify-content: center;
  }

  .favorites_radios_wrap > .favorites_radios_item > svg {
    opacity: 1;
  }

  .favorites_radios_wrap > .favorites_radios_item:hover {
    transform: scale(0.95);
  }
}

.favorites_channels .title {
  padding-left: 10px;
}

@media (max-width: 1024px) {
  .favorites_channels .title {
    padding-left: 0;
  }
}

.favorite_modal .info_popup_img_holder {
  background: url(../../images/mini_logo.png);
  background-size: contain !important;
}