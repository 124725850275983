@import "../../../../reskin/reskin.css";

.Channels {
  position: relative !important;
}

.Hidden {
  display: none;
}

.Channels p {
  color: #fff;
}

.Channels .currentEpg {
  background-color: #242f33;
  display: flex;
  align-items: center;
  height: 38px;
  position: relative !important;
  z-index: 2;
}

.Channels .currentEpg .openEpgs {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 5px;
}

.Channels .currentEpg .openEpgs svg {
  color: #fff;
  width: 28px;
  height: 28px;
}

.Channels .currentEpg .epgInfo {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.Channels .currentEpg .epgInfo {
  height: 25px;
}

.Channels .currentEpg .epgTime {
  padding-left: 20px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.4px;
  white-space: nowrap;
}

.Channels .currentEpg .epgName {
  padding-left: 20px;
  padding-right: 5px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.4px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Channels .listOfChannels {
  /* Nav.height + BottomNav.height = 120; VideoPlayer.height = 32vh; currentEpg.height = 38px; */
  height: calc(100vh - 120px - 32vh - 38px);
  /* position: fixed; */
  width: 100%;
  padding-bottom: 55px;
  overflow: scroll;
  background-color: var(--navBackground);
  overflow-x: hidden;
}

.Channels .listOfChannels .channel {
  height: 48px;
  background-color: var(--navBackground);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-bottom: 2px #000 solid;
  cursor: pointer;
}

.Channels .listOfChannels .channel img {
  height: 30px;
}

.Channels .listOfChannels .channel .channelInfo {
  display: flex;
  align-items: center;
}

.Channels .listOfChannels .channel .channelInfo p {
  margin-left: 10px;
  font-size: 18px;
}

.Channels .listOfChannels .channel .channelButtons {
  display: flex;
}

.Channels .listOfChannels .channel .channelButtons svg {
  color: #fff;
  margin-right: 5px;
  margin-left: 5px;
  width: 20px;
  height: 20px;
}

.LowOpacity {
  opacity: 0.24;
}

svg.catch-up {
  transform: rotateY(180deg);
}

.listOfEpgs {
  /* Nav.height + BottomNav.height = 120; VideoPlayer.height = 32vh; currentEpg.height = 38px; */
  height: calc(100vh - 120px - 32vh - 38px);
  /* position: fixed; */
  padding: 8px 0px 20px 0px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: var(--navBackground);
}

.listOfEpgs::-webkit-scrollbar {
  width: 8px;
}

.listOfEpgs::-webkit-scrollbar-thumb {
  background: var(--navBarMain);
}

.listOfEpgs .arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.listOfEpgs .arrow.up {
  position: fixed;
  top: 47vh;
  z-index: 1;
}

.listOfEpgs .arrow.down {
  position: fixed;
  bottom: 48px;
  z-index: 1;
}

.listOfEpgs img {
  width: 100px;
  height: 15px;
  /* text-align: center; */
}

.singleEpg {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  padding: 4px;
  padding-right: 34px;
  height: 36px;
  background: var(--navBackground);
}

.singleEpg .time {
  margin-left: 16px;
  color: #ccc;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.singleEpg .name {
  margin-left: 24px;
  color: #ccc;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.singleEpg .time.active,
.singleEpg .name.active {
  color: #fff;
  font-weight: 600;
}

.singleEpg .name.active:nth-of-type(2) {
  margin-left: 20px;
}

.singleEpg svg {
  position: absolute;
  right: 8px;
  width: 18px;
  height: 18px;
}

@media (orientation: landscape) {
  .Channels .listOfChannels {
    height: calc(148vh - 120px - 32vh - 38px);
    padding-bottom: 100px;
  }

  .listOfEpgs {
    height: calc(148vh - 120px - 32vh - 38px);
    padding: 8px 0px 80px 0px;
  }
}
