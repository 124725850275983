.Movies {
  min-height: 80vh;
}

.single-movie .img-holder {
  max-width: 200px;
  height: 300px;
  margin-bottom: 5px;
}

.Movies .single-movie img {
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: 300ms ease-in-out;
  cursor: pointer;
  object-fit: cover;
  border-radius: 5px;
  z-index: 30;
}

.single-movie {
  position: relative;
  margin: 0 15px;
  margin-bottom: 30px;
  z-index: 3;
}

.Movies .single-movie h2 {
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  color: rgb(44, 62, 80);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.Movies .single-movie p {
  font-size: 17px;
  font-weight: 500;
  color: rgb(44, 62, 80);
}

.movies-section {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  justify-content: space-between;
  padding: 35px 30px;
}

.watched {
  display: none;
}

.play-movie {
  display: none;
}

.single-movie .img-overlay {
  /* display: none; */
  transform: scale(1);
  width: 200px;
  height: 300px;
  transition: 300ms ease-in-out;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
}

.single-movie:hover .img-overlay {
  /* display: block; */
  transition: 300ms ease-in-out;
  transform: scale(1.28);
}

.single-movie:hover img {
  transform: scale(1.28);
  transition: 300ms ease-in-out;
}

.single-movie:hover .watched {
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  left: -22px;
  top: -38px;
  z-index: 2;
}

.watched svg {
  font-size: 28px;
  margin-right: 6px;
}

.watched span {
  font-size: 18px;
  font-weight: 600;
}
.single-movie:hover .play-movie {
  display: block;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  color: #fff;
  cursor: pointer;
  width: 100px;
  height: 100px;
}

.play-movie svg {
  width: 100%;
  height: 100%;
  transition: 300ms ease-in-out;
}

.play-movie svg:hover {
  color: var(--main);
  transition: 300ms ease-in-out;
}

/* @media (max-width: 1850px) {
  .movies-section {
    grid-template-columns: repeat(7, 1fr);
  }
} */

@media (max-width: 1650px) {
  .movies-section {
    grid-template-columns: repeat(6, 1fr);
  }
}

/* @media (max-width: 1420px) {
  .movies-section {
    grid-template-columns: repeat(5, 1fr);
  }
} */

@media (max-width: 1200px) {
  .movies-section {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 950px) {
  .movies-section {
    grid-template-columns: repeat(3, 1fr);
  }
}
