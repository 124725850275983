@import "../reskin/reskin.css";

.login_wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url(../images/login.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
}

.login_wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
}

.login_content {
  background: linear-gradient(
    153deg,
    hsla(0, 0%, 100%, 0.05),
    hsla(0, 0%, 100%, 0.01) 35%,
    rgba(0, 0, 0, 0.01) 60%,
    hsla(0, 0%, 100%, 0.05)
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 660px;
  position: relative;
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.25);
  z-index: 99;
}

.login_content.not-demo {
  height: 580px;
}

.login_content.register {
  height: 620px;
}

.login_content.register.not-demo {
  height: 550px;
}

.login_content img {
  width: 200px;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.login_content form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px 20px 20px;
}

.login_content form label {
  width: 100%;
  display: block;
  margin-bottom: 5px;
  color: #a3a3a3;
  font-size: 16px;
}

.login_content form input:not([class="register_submit"]) {
  width: 100%;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #a3a3a3;
  color: #fff;
  padding: 5px 0;
  margin-bottom: 20px;
  font-size: 16px;
  padding-right: 30px;
}

.login_content form .privacyPolicy {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}
.login_content form .privacyPolicy .checkbox {
  margin-right: 12px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.login_content form .privacyPolicy .checkbox.active {
  background: var(--main);
}

.login_content form .privacyPolicy .checkbox svg {
  color: #fff;
  width: 15px;
  height: 15px;
}

.login_content form .privacyPolicy p {
  color: #a3a3a3;
}

.login_content form .privacyPolicy p span {
  color: var(--main);
  cursor: pointer;
}

.login_content .input_holder {
  width: 100%;
  position: relative;
}

.login_content .input_holder svg {
  position: absolute;
  top: 4px;
  right: 0;
  color: #a3a3a3;
  cursor: pointer;
}

.login_content .dont_acc {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a3a3a3;
  font-size: 16px;
  font-weight: 400;
  margin: 0px 0 30px 0;
}

.login_content .dont_acc a {
  color: var(--main);
  font-size: 16px;
  margin-left: 5px;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  z-index: 5;
}

.login_content .dont_acc a:hover {
  opacity: 0.8;
}

.login_content form input[class="register_submit"] {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  background: var(--main);
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 0;
  letter-spacing: 1px;
}

.login_content form input[class="register_submit"]:hover {
  background: #fff;
  color: #333;
}

.login_content form input[class="register_submit"]:disabled {
  background: #ccc;
}

.login_content .demoBtn {
  width: 90%;
  height: 50px;
  border-radius: 4px;
  background: var(--main);
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 0;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.login_content .demoBtn:hover {
  background: #fff;
  color: #333;
}

.login_content .custom_select {
  width: 100%;
  height: 40px;
  position: relative;
  margin-bottom: 15px;
}

.login_content .custom_select .capital {
  width: 100%;
  height: 40px;
  border: 1px solid #9b9b9b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  border-radius: 4px;
  color: #9b9b9b;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.login_content .custom_select .capital svg {
  transition: all 0.3s ease-in-out;
}

.login_content .custom_select .capital.rotate svg {
  transform: rotate(-180deg);
}

.login_content .custom_select .options {
  position: absolute;
  left: 0;
  right: 0;
  top: 37px;
  border: 1px solid #9b9b9b;
  background: #000;
  max-height: 210px;
  border-radius: 0 0 4px 4px;
  overflow-y: scroll;
  z-index: 10;
}

.login_content .custom_select .options::-webkit-scrollbar {
  width: 0;
}

.login_content .custom_select .options p {
  padding: 10px 15px;
  color: #9b9b9b;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.login_content .custom_select .options p:hover {
  color: #fff;
}

.login_content .error-message {
  margin: -20px 0 5px 0;
}

.verifikacioni-kod {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.verifikacioni-kod .pozadina {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}

.verifikacioni-kod .unos {
  position: relative;
  width: 40%;
  height: 400px;
  background: url(../images/login.jpg);
  margin: 10% auto;
  border-radius: 25px;
  min-width: 400px;
  color: #7a6d84;
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.25);
}

.verifikacioni-kod .unos .kod p {
  text-align: center;
  font-size: 25px;
  margin: 0 10px;
  margin-bottom: 10%;
}

.verifikacioni-kod .unos .kod .verification-err {
  font-size: 24px;
  color: red;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 0 !important;
  background-color: rgba(255, 255, 255, 0.6);
}

.verifikacioni-kod .unos input {
  width: 40%;
  height: 35px;
  border-radius: 3px;
  background-color: #fafafa;
  margin: 0 30%;
  padding: 0 20px;
  font-size: 16px;
  text-align: center;
  font-family: font_regular;
}

.verifikacioni-kod .unos .kod {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.verifikacioni-kod .unos .top {
  width: 100%;
  /* background: url(../assets/img/gradient.png); */
  background: rgba(0, 0, 0, 0.8);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  box-shadow: 0 0 14.5px rgba(0, 0, 0, 0.4);
  padding: 20px 0;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 2px;
}

.verifikacioni-kod button {
  border: 0;
  color: var(--white);
  height: 47px;
  border-radius: 3px;
  filter: drop-shadow(0.61px 6.973px 8px rgba(0, 0, 0, 0.27));
  background: var(--main);
  color: white;
  text-transform: uppercase;
  /* font-family: font_bold; */
  font-size: 19px;
  letter-spacing: 0px;
  line-height: 29px;
  /* font-weight: bold; */
  border-radius: 8px;
  width: 25%;
  margin: 20px 37.5%;
}
.verifikacioni-kod button:hover {
  background: #fff;
  color: #333;
}

.undefined {
  width: 350px;
  margin: 50px auto;
}

.undefined div {
  display: flex;
  width: 0;
  border: none;
}

.undefined div input {
  outline: none;
}

/* TOC */
.toc {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toc .bg {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}

.termsWrapper {
  background-color: #fff;
  z-index: 2;
}

.tocController {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.middleBtns {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.termsWrapper .btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 30px;
  padding: 5px;
}

.termsWrapper .btn p {
  margin: 0 6px;
  font-size: 18px;
}

.termsWrapper .btn svg {
  color: var(--main);
  width: 25px;
  height: 25px;
}

.middleBtns .btn:nth-child(2) {
  border-left: 1px solid #000;
}

.verificationCode {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verificationCode .vCodeWrapper {
  width: 450px;
  height: 580px;
  background-color: var(--navBackground);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  padding: 10px;
  z-index: 2;
}

.verificationCode .vCodeWrapper p {
  font-size: 20px;
  text-align: center;
  color: #fff;
}

.verificationCode .vCodeWrapper img {
  width: 120px;
}

.verificationCode .vCodeWrapper button {
  width: 60%;
  height: 50px;
  font-size: 20px;
  background-color: var(--main);
  border: none;
  color: #fff;
  border-radius: 12px;
}

.verificationCode .vCodeWrapper .sendAgain {
  margin-top: 60px;
  text-align: center;
}

.verificationCode .vCodeWrapper .sendAgain span {
  color: var(--main);
  cursor: pointer;
  font-size: 20px;
}

.verificationCode .bg {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}

.code-sent {
  color: greenyellow !important;
  font-size: 18px;
}

.verification-err {
  color: red !important;
  font-size: 18px;
}

.phoneInput {
  width: 100%;
  margin-bottom: 30px;
}

.phoneInput input {
  padding-left: 50px !important;
}

.contact-bottom {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-bottom p {
  color: #ccc;
}
.contact-bottom a {
  text-decoration: none;
  color: #fff;
  background-color: var(--main);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  border-radius: 4px;
  padding: 6px 0;
  margin-top: 6px;
}
.contact-bottom svg {
  margin-right: 12px;
}
