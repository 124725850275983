@import "../../../reskin/reskin.css";

.carousel_wrapper {
  width: 100%;
  position: relative;
  height: 1100px;
}

.carousel_holder {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
}

.carousel_holder > .carousel_item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center top !important;
  background-attachment: fixed !important;
  display: none;
  align-items: flex-end;
  padding: 0 55px;
}

.active_slider {
  display: flex !important;
  animation: slideranimation 0.8s forwards;
}

@keyframes slideranimation {
  from {
    opacity: 0;
    transform: perspective(1000px) rotateY(50deg) scale(2);
  }
  to {
    opacity: 1;
    transform: perspective(0) rotateY(0) scale(1);
  }
}

.carousel_holder > .carousel_item > div {
  color: #fff;
  animation: sliderText 1s forwards;
  position: absolute;
  bottom: 450px;
  left: 150px;
}

@keyframes sliderText {
  70% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.carousel_holder > .carousel_item > div h2 {
  font-size: 2.7em;
  text-transform: uppercase;
  font-weight: bold;
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4);
}

.carousel_holder > .carousel_item > div p {
  font-size: 1.7em;
  font-weight: 400;
  margin: 10px 0 20px 0;
}

.carousel_holder > .carousel_item > div button {
  width: 215px;
  height: 40px;
  border-radius: 7px;
  background: #526167;
  color: #fff;
  font-weight: bold;
  border: 0;
  text-transform: uppercase;
  font-size: 18px;
}

.carousel_holder > .carousel_item > div button:hover {
  background: linear-gradient(var(--main), var(--secondaryGradient));
}

.carousel_arrow_left {
  position: fixed;
  top: 40%;
  font-size: 4.5em;
  color: rgba(255, 255, 255, 0.4);
  left: 50px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.carousel_arrow_right {
  position: fixed;
  top: 40%;
  font-size: 4.5em;
  color: rgba(255, 255, 255, 0.4);
  right: 50px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.carousel_arrow_left:hover {
  color: rgba(255, 255, 255, 0.8);
}

.carousel_arrow_right:hover {
  color: rgba(255, 255, 255, 0.8);
}

.carousel_circles {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 430px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  z-index: 1;
}

.carousel_circles .circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #323e43;
  margin: 0 10px;
  cursor: pointer;
}

.circle_active {
  background: #526167 !important;
}

@media (max-width: 1700px) {
  .carousel_wrapper {
    height: 1000px;
  }

  .carousel_circles {
    bottom: 380px;
  }

  .carousel_holder > .carousel_item > div {
    bottom: 400px;
  }
}

@media (max-width: 1500px) {
  .carousel_wrapper {
    height: 900px;
  }

  .carousel_circles {
    bottom: 300px;
  }

  .carousel_holder > .carousel_item > div {
    bottom: 350px;
  }

  .carousel_holder > .carousel_item > div h2 {
    font-size: 2.2em;
  }

  .carousel_holder > .carousel_item > div p {
    font-size: 1.3em;
    margin: 0 0 10px 0;
  }

  .carousel_arrow_left {
    font-size: 3em;
  }

  .carousel_arrow_right {
    font-size: 3em;
  }
}

@media (max-width: 1400px) {
  .carousel_wrapper {
    height: 900px;
  }
}

@media (max-width: 1300px) {
  .carousel_wrapper {
    height: 850px;
  }

  .carousel_holder > .carousel_item > div {
    bottom: 300px;
  }

  .carousel_circles {
    bottom: 270px;
  }
}

@media (max-width: 1100px) {
  .carousel_wrapper {
    height: 900px;
  }

  .carousel_holder > .carousel_item > div {
    position: absolute;
    bottom: 350px;
    left: 100px;
  }

  .carousel_arrow_left {
    top: 50%;
    transform: translateY(-50%);
  }

  .carousel_arrow_right {
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (max-width: 1024px) {
  .carousel_circles {
    bottom: 330px;
  }
}

@media (max-width: 900px) {
  .carousel_holder > .carousel_item > div {
    bottom: 370px;
    left: 50px;
  }

  .carousel_holder > .carousel_item > div h2 {
    font-size: 24px;
  }

  .carousel_holder > .carousel_item > div p {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .carousel_holder > .carousel_item > div button {
    width: 120px;
    height: 30px;
    font-size: 14px;
  }

  .carousel_arrow_left {
    left: 10px;
    font-size: 40px;
  }

  .carousel_arrow_right {
    font-size: 40px;
    right: 10px;
  }

  .carousel_circles {
    bottom: 315px;
  }
}
