@import "../../../../reskin/reskin.css";

.Nav {
  background-color: var(--navBackground);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0px;
  padding: 0px 16px;
  width: 100%;
  height: 64px;
  z-index: 20;
}

.Nav img {
  width: 120px;
}

.Border {
  background: var(--navBarMain);
  height: 3px;
  width: 100%;
  position: absolute;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.language-select-container {
  border: 2px solid #fff;
  border-radius: 4px;
  padding: 3px 6px;
}

.language-select {
  border: none;
  color: #fff;
  font-weight: 600;
  background: transparent;
}

.language-select option {
  color: #000;
  font-weight: 600;
}
