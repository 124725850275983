@import "../../../reskin/reskin.css";

.radio_slider {
  padding: 10px 60px 30px 60px;
  position: relative;
}

.radio_slider .row {
  padding-left: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.radio_slider .row a {
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #000;
  transition: all 0.3s ease-in-out;
  font-weight: lighter;
}

.radio_slider .row a svg {
  font-size: 24px;
  margin-top: 5px;
  transition: all 0.3s ease-in-out;
  color: #000;
}

.radio_slider .row a:hover {
  color: var(--main);
}

.radio_slider .row a:hover svg {
  transform: translateX(5px);
  color: var(--main);
}

.radio_slider_wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 140px;
  grid-gap: 0 10px;
  margin-left: 10px;
}

.radio_slider_wrapper .radio_slider_item {
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0 0 12px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform: scale(0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.radio_slider_wrapper .radio_slider_item:hover {
  transform: scale(1.1);
}

.radio_slider_wrapper .radio_slider_item > img {
  width: 120px;
}

/* Media */
@media (max-width: 1500px) {
  .radio_slider {
    padding: 10px 30px 20px 30px;
  }

  .radio_slider .row {
    padding: 0;
    padding-left: 13px;
  }

  .radio_slider .row .title {
    font-size: 28px;
  }

  .radio_slider .row a {
    font-size: 21px;
  }

  .radio_slider .row a svg {
    font-size: 16px;
    margin: 0;
  }

  .radio_slider_wrapper {
    grid-gap: 0 15px;
  }
}

@media (max-width: 1400px) {
  .radio_slider_wrapper {
    grid-auto-rows: 110px;
    grid-gap: 0 10px;
  }

  .radio_slider_wrapper .radio_slider_item > img {
    width: 80px;
  }
}

@media (max-width: 1024px) {
  .radio_slider .row {
    padding: 0;
    padding-left: 10px;
  }

  .radio_slider_wrapper {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 150px;
    grid-gap: 0 10px;
  }

  .radio_slider_wrapper .radio_slider_item {
    transform: scale(1);
  }

  .radio_slider_wrapper .radio_slider_item:hover {
    transform: scale(1);
  }

  .radio_slider_wrapper .radio_slider_item > img {
    width: 110px;
  }
}

@media (max-width: 800px) {
  .radio_slider_wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 160px;
    grid-gap: 0 10px;
  }
}

@media (max-width: 500px) {
  .radio_slider {
    padding-bottom: 30px;
  }

  .radio_slider .row {
    padding: 0;
  }

  .radio_slider_wrapper {
    margin: 0;
    grid-template-columns: 100%;
    grid-gap: 0;
  }
}
