.now_on_tv_screen .channels_track_list_icon {
  width: 0;
  height: 0;
}

.now_on_tv_screen .rotate {
  transform: rotate(180deg);
}

.now_on_tv_screen .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
}

.now_on_tv_screen .row > div {
  display: flex;
  align-items: center;
}

.now_on_tv_no_items {
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  top: 235px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #777;
  font-size: 26px !important;
}

.now_on_tv_no_items svg {
  font-size: 3em;
  fill: #777;
}

.see_more_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.see_more_wrapper .see_more_btn {
  width: 350px;
  height: 50px;
  cursor: pointer;
  margin: 20px 0;
  border: 0;
  border-radius: 8px;
  background: linear-gradient(var(--main), var(--secondaryGradient));
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
}

.see_more_wrapper .see_more_btn:hover {
  opacity: 0.9;
}
