@import "../../../../reskin/reskin.css";

.More {
  background: var(--navBackground);
  height: 100vh;
  margin-top: 64px;
}

.More .more-link {
  display: block;
  color: #fff;
  font-size: 30px;
  text-align: center;
  border-bottom: 1px solid #fff;
  padding: 10px 0;
}
