@import "../../../../reskin/reskin.css";

.channels_menu {
  width: 450px;
  height: 100%;
  box-shadow: inset -30px 0 20px -20px rgba(0, 0, 0, 0.1);
  position: relative;
}

.menu_bars_holder {
  display: none;
}

.channels_menu_holder {
  position: relative;
  width: 100%;
  height: 100%;
}

.channels_menu_holder .input_holder {
  width: 85%;
  height: 50px;
  margin: 0 auto;
  position: relative;
  margin-top: 15px;
}

.channels_menu_holder .input_holder input {
  width: 100%;
  height: 50px;
  font-size: 16px;
  padding-right: 50px;
  background: #fff;
  padding: 10px 50px 10px 10px;
  color: #323e43b7;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #e4e4e4;
  font-style: normal;
  font-weight: 550;
}

.channels_menu_holder .input_holder input::placeholder {
  font-style: italic;
  font-weight: normal;
}

.channels_menu_holder .input_holder::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 100%;
  background: url(../../../../images/search2.png) 50% no-repeat;
  background-size: 22px;
}

.channels_menu_holder .items {
  width: 100%;
  padding: 15px 8% 10px;
  overflow-y: scroll;
  height: calc(100% - 70px);
}

.channels_menu_holder .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  /* transform: scaleX(1); */
  background: transparent;
  padding: 0 10px;
  /* position: relative; */
  border: 3px solid transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
  transition: all 0.2s ease;
}

.channels_menu_holder .history_svg {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.channels_menu_holder .item::before {
  content: "";
  width: 100%;
  height: 0;
  position: absolute;
  bottom: -3px;
  left: 0;
  border-bottom: thin solid #e5e5e5;
}

.channels_menu_holder .item .left {
  display: flex;
  align-items: center;
}

.channels_menu_holder .item .right {
  position: relative;
  display: flex;
  align-items: center;
}

.channels_menu_holder .item .right .lock {
  font-size: 20px;
  margin-right: 5px;
}

.channels_menu_holder .item .right svg {
  font-size: 24px;
}

.channels_menu_holder .item .right svg.play {
  color: var(--main);
  font-size: 21px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.channels_menu_holder .item .left img {
  width: 45px;
}

.channels_menu_holder .item p {
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
  font-size: 16px;
  color: #000;
  text-transform: uppercase;
  margin-left: 20px;
}

.channels_menu_holder .item.active {
  border: 3px solid var(--main);
  z-index: 9;
}

.channels_menu_holder .item.active::before {
  display: none;
}

.channels_menu_holder .item:hover {
  transform: scale3D(1.05, 1.05, 1.05);
  background: #fff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  z-index: 10;
  color: #2c3e50;
}

/* width */
.channels_menu_holder .items::-webkit-scrollbar {
  width: 7px;
  border-radius: 30px;
}

/* Track */
.channels_menu_holder .items::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.channels_menu_holder .items::-webkit-scrollbar-thumb {
  background: rgb(197, 197, 197);
  border-radius: 30px;
}

/* Handle on hover */
.channels_menu_holder .items::-webkit-scrollbar-thumb:hover {
  background: rgb(197, 197, 197);
}

/* Media */
@media (max-width: 1500px) {
  .channels_menu_holder .item .left img {
    width: 35px;
  }

  .channels_menu_holder .item p {
    font-size: 13px;
    margin-left: 10px;
  }

  .channels_menu_holder .item .right .lock {
    font-size: 16px;
  }

  .channels_menu_holder .item .right svg {
    font-size: 18px;
  }
}

@media (max-width: 1300px) {
  .channels_menu {
    width: 400px;
  }
}

@media (max-width: 1150px) {
  .channels_menu {
    width: 350px;
  }

  .channels_menu_holder .input_holder {
    width: 90%;
    height: 45px;
  }

  .channels_menu_holder .input_holder input {
    height: 45px;
  }

  .channels_menu_holder .items {
    padding: 15px 3% 10px 5%;
  }

  .channels_menu_holder .item {
    height: 50px;
    border: 1px solid transparent;
  }

  .channels_menu_holder .item.active {
    border: 1px solid var(--main);
  }
}

@media (max-width: 900px) {
  .channels_menu {
    width: 300px;
    position: fixed;
    top: 160px;
    bottom: 0;
    left: 0;
    z-index: 99;
    background: #eee;
    transition: all 0.3s ease-in-out;
    transform: translate(-350px);
  }

  .channels_menu.active {
    transform: translate(0);
  }

  .menu_bars_holder {
    display: block;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    background: linear-gradient(var(--main), var(--secondaryGradient));
    color: #fff;
    font-size: 24px;
    width: 50px;
    height: 40px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.wait {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wait svg {
  transition: all 0.5s ease-in-out;
  animation: rotate 1s linear infinite;
  font-size: 4em;
  color: var(--main);
}
