.LoginMobile {
  background: url("../../../../images/loginMobileBg.jpg");
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.LoginMobile img {
  max-width: 300px;
}
.LoginMobile h1 {
  color: #fff;
  text-align: center;
  margin: 40px 0;
  font-size: 20px;
}
.LoginMobile .logo {
  margin-top: 50px;
}
.LoginMobile .store-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.LoginMobile .store-buttons a {
  width: 60%;
  margin-bottom: 40px;
}
.LoginMobile .store-buttons a img {
  width: 100%;
  /* margin-bottom: 40px; */
}
