.news-feed-container {
  padding: 2px 16px;
  padding-bottom: 56px;
  background-color: var(--navBackground);
  height: calc(100vh - 56px - 64px);
  overflow-y: auto;
}

.news-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 16px;
}

.news-item-image {
  border-radius: 6px;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.news-item-title {
  margin: 6px 0px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.news-item-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.news-item-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.news-item-info svg {
  width: 20px;
  height: 20px;
  color: #fff;
}

.news-item-date {
  margin: 0px 12px;
  color: #fff;
}

.news-item-logo {
  width: 48px;
  height: auto;
}