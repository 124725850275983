.SingleMovie {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 300;
}

.SingleMovie .movieContent {
  width: 60%;
  height: 90%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  background: #fff;
}

.SingleMovie .movieContent .movieTop {
  height: 65%;
  background: #323e43;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.SingleMovie .movieContent .movieTop img {
  height: 100%;
}

.SingleMovie .movieContent .movieTop h2 {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 36px;
  margin-left: 40px;
  margin-bottom: 20px;
  font-weight: 500;
}

.SingleMovie .movieContent .movieTop .play-single-movie {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.SingleMovie .movieContent .movieTop .play-single-movie svg {
  font-size: 100px;
  color: #fff;
  cursor: pointer;
  transition: 300ms all;
}

.SingleMovie .movieContent .movieTop .play-single-movie svg:hover {
  color: var(--main);
  transition: 300ms all;
}

.SingleMovie .movieContent .movieTop .watched-single {
  position: absolute;
  left: 12px;
  top: 12px;
  display: flex;
  align-items: center;
  color: #fff;
}

.SingleMovie .movieContent .movieTop .watched-single svg {
  font-size: 30px;
  margin-right: 6px;
}

.SingleMovie .movieContent .movieTop .watched-single span {
  font-size: 18px;
}

.SingleMovie .movieContent .movieTop .close-movie {
  position: absolute;
  right: 14px;
  top: 12px;
  color: #fff;
  cursor: pointer;
}
.SingleMovie .movieContent .movieTop .close-movie svg {
  font-size: 36px;
}

.SingleMovie .movieContent .movieButtons {
  height: 70px;
  padding: 14px 40px;
  background: rgb(240, 240, 240);
  display: flex;
  align-items: center;
}

.SingleMovie .movieContent .movieButtons button {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 35px;
  background: #fff;
  border-radius: 6px;
  height: 35px;
  width: 220px;
  color: #222222;
  font-size: 16px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  transition: 300ms all;
}

.SingleMovie .movieContent .movieButtons button:hover {
  transition: 300ms all;
  background: var(--main);
  color: #fff;
}

.SingleMovie .movieContent .movieButtons button:hover svg {
  color: #fff !important;
	fill: #fff;
}

.SingleMovie .movieContent .movieButtons button svg {
  margin-right: 6px;
  font-size: 22px;
	width: 22px;
	height: 22px;
  color: #6f777b;
	fill: #6f777b;
}

.SingleMovie .movieContent .movieButtons button img {
	margin-right: 6px;
  width: 22px;
  /* color: #6f777b; */
}

.SingleMovie .movieContent .movieDescription {
  padding: 18px 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}
