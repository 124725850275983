.TermsContitionsModal {
  background-color: #fff;
  z-index: 2;
  height: 70%;
  /* width: 980px;
  height: 600px; */
}

.TermsContitionsModal .TermsContitionsModal-body {
  height: 90%;
  overflow-y: auto;
  padding: 40px;
}
.TermsContitionsModal .TermsContitionsModal-footer {
  position: relative;
  height: 10%;
  display: flex;
  align-items: center;
}

.TermsContitionsModal .TermsContitionsModal-footer .modal-decline {
  position: absolute;
  display: flex;
  justify-content: left;
  align-items: center;
  left: 40px;
  height: 60%;
  width: 200px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.TermsContitionsModal .TermsContitionsModal-footer .modal-decline:hover {
  background: var(--main);
  color: #333;
}

.TermsContitionsModal .TermsContitionsModal-footer .modal-decline svg {
  height: 25px;
  margin-right: 10px;
  fill: var(--main);
}

.TermsContitionsModal .TermsContitionsModal-footer .modal-accept {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 40px;
  height: 60%;
  width: 200px;
  border-radius: 5px;
  background-color: var(--main);
  font-weight: 500;
  font-size: 20px;
  color: white;
  cursor: pointer;
}

.TermsContitionsModal .TermsContitionsModal-footer .modal-accept:hover {
  background: #fff;
  color: #333;
}

.TermsContitionsModal .btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 30px;
  padding: 5px;
}

.TermsContitionsModal .btn p {
  margin: 0 6px;
  font-size: 18px;
}

.TermsContitionsModal .btn svg {
  color: var(--main);
  width: 25px;
  height: 25px;
}

.tocController {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.middleBtns {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.middleBtns .btn-control {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 30px;
  padding: 5px;
}

.middleBtns .btn-control svg {
  color: var(--main);
  width: 25px;
  height: 25px;
}

.middleBtns .current-page {
  font-weight: 600;
}


@media (max-width: 800px) {
  .TermsContitionsModal {
    width: 90%;
  }
}

@media(min-width: 800px) and (max-width: 1200px) {
  .TermsContitionsModal {
    width: 80%;
  }
}

@media (min-width: 1200px) {
  .TermsContitionsModal {
    width: 50%;
  }
}