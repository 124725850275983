@import "../../../reskin/reskin.css";

.addM3U8list_wrapper .title {
  margin: 50px 0 70px 0;
  text-align: center;
}

.addM3U8list_wrapper .row .input_field {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.addM3U8list_wrapper .input_field input {
  display: block;
  width: 500px;
  height: 40px;
  border: 0;
  background: #fff;
  border-radius: 8px;
  padding: 0 10px;
  margin-bottom: 20px;
}

.addM3U8list_wrapper .input_field button {
  width: 150px;
  height: 40px;
  border-radius: 8px;
  border: 0;
  color: #fff;
  background: linear-gradient(var(--main), var(--secondaryGradient));
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.selectByCountry {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.selectByCountry h2 {
  margin-bottom: 10px;
}

@media (max-width: 700px) {
  .addM3U8list_wrapper .title {
    margin: 20px 0;
    text-align: center;
  }

  .addM3U8list_wrapper .input_field input {
    width: 80%;
  }
}
