.Home {
  position: relative;
  background: #000;
  /* overflow-y: scroll !important; */
  /* height: 100vh !important; */
}

/* .Home .FloatingText {
  top: calc(32vh + 50px);
} */
