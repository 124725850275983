@import "../../../reskin/reskin.css";

.bottom_slider_relative.none {
  display: none;
}

.bottom_slider_relative .player_loader {
  font-size: 10px;
}

.bottom_slider {
  display: flex;
  align-items: flex-end;
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 155px;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.bottom_slide {
  position: relative;
  height: 130px;
  color: #fff;
  margin-top: auto;
  background: #222;
  border-radius: 10px;
}

.bottom_slide_holder::after {
  content: "";
  width: 100%;
  height: 60%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.8));
}

.bottom_slide_holder {
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 5px 10px;
  border-radius: 10px;
  overflow: hidden;
  perspective: 1px;
}

.bottom_slide_holder div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom_slide_holder .image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bottom_slide_holder .no_image {
  background-color: #eeeeee;
}

.bottom_slide_holder .no_image img {
  width: 50%;
  height: 50%;
}

.tumbnail {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bottom_slide.active {
  background: var(--main);
  border-radius: 10px;
  padding: 3px;
}

.bottom_slide.active .bottom_slide_holder::after {
  bottom: 3px;
  left: 3px;
  right: 3px;
  width: auto;
}

.bottom_slide h3 {
  font-size: 16px;
  font-weight: 500;
  z-index: 1;
}

.bottom_slide p {
  font-size: 16px;
  font-weight: 500;
  z-index: 1;
}

.bottom_slide .images {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.bottom_slide .images img {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  cursor: pointer;
}

.marker_holder {
  position: absolute;
  top: -30px;
  width: 50px;
  z-index: 5;
}

.marker_holder .marker {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marker_holder .marker::before {
  content: "";
  position: absolute;
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
  height: 250px;
  width: 4px;
  background: linear-gradient(90deg, #5e0404, #c60000);
}

.marker_holder img {
  transform: rotate(90deg);
  position: relative;
  width: 50px;
}

@media (max-width: 1500px) {
  .bottom_slide .images {
    top: 30%;
  }

  .bottom_slide .images img {
    width: 40px;
  }
}

@media (max-width: 1350px) {
  .bottom_slider {
    left: 220px;
  }

  .bottom_slide h3 {
    font-size: 14px;
  }

  .bottom_slide p {
    font-size: 14px;
  }
}

@media (max-width: 1100px) {
  .bottom_slider {
    left: 200px;
  }

  .bottom_slide {
    padding: 5px;
  }

  .bottom_slide h3 {
    font-size: 13px;
  }

  .bottom_slide p {
    font-size: 13px;
  }

  .bottom_slide .images img {
    width: 30px;
  }
}

.bottom_slider_relative .swiper-button-next {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: unset;
  bottom: 60px;
  right: 5px;
}

.bottom_slider_relative .swiper-button-next::after {
  width: 30px;
  height: 30px;
  font-size: 27px;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom_slider_relative .swiper-button-next:focus {
  outline: none !important;
}

.bottom_slider_relative .swiper-button-prev {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: unset;
  bottom: 60px;
  left: 5px;
}

.bottom_slider_relative .swiper-button-prev::after {
  width: 30px;
  height: 30px;
  font-size: 27px;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom_slider_relative .swiper-button-prev:focus {
  outline: none !important;
}

.marker_center_wrap {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -10px !important;
  bottom: 3px;
  width: 4px;
}

.marker_center_wrap .holder {
  position: relative;
  height: 100%;
}

.marker_center_wrap .holder .line {
  position: absolute;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 4px;
  background: #a20000;
}

.bottom_slider .marker_center {
  margin: 0 !important;
  width: 40px !important;
  height: auto !important;
  transform: rotate(90deg);
  position: absolute;
  left: -18px;
}

.bottom_slider .left_marker {
  width: 45px;
  z-index: 10;
  position: absolute;
  left: 0;
  top: 42px;
  transform: rotate(180deg);
  display: none;
}

.bottom_slider .right_marker {
  width: 45px;
  z-index: 10;
  position: absolute;
  right: 0;
  top: 42px;
  display: none;
}
