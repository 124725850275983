.FloatingTextContainer {
  width: 100%;
  line-height: 100px;
  background-color: #000;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 12;
}

.FloatingTextContainer p {
  display: inline-block;
  padding-left: 100%;
  font-size: 44px;
  animation: marquee 30s linear infinite;
}

@media (max-width: 768px) {
  .FloatingTextContainer {
    line-height: 64px;
  }

  .FloatingTextContainer p {
    font-size: 36px;
  }
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

@-webkit-keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

@-moz-keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
