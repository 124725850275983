.MoviesTrack {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  border-bottom: 1px solid #ccc;
  padding: 0 40px;
  z-index: 1;
  background: #eee;
}
.movies-track-right {
  display: flex;
  align-items: center;
}
.input-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #323e43;
  padding-bottom: 4px;
}
.input-container svg {
  font-size: 30px;
  color: #323e43;
  margin: 0;
  margin-right: 6px;
}
.input-container input {
  border: none;
  background: transparent;
  font-size: 20px;
  color: #7d7d7d;
}
