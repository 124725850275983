@import "../../../reskin/reskin.css";

.radio_component .channels_track_list_icon {
  width: 0 !important;
}

.radio_wrapper {
  padding: 20px 0;
  background: #fff;
  min-height: calc(100vh - 260px);
}

.radio_wrapper .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
}

.radio_wrapper .title {
  display: flex;
  align-items: center;
}

.title > span {
  margin-left: 10px;
  min-width: 36px;
  height: 32px;
  border-radius: 3px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #616161;
}

.radio_stations {
  padding-top: 30px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 140px;
  grid-gap: 50px 10px;
}

.radio_stations_item {
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0 0 12px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform: scale(0.95);
  border: 4px solid transparent;
}

.radio_component_video > div {
  width: 0 !important;
  height: 0 !important;
}

.radio_stations_item .radio_poster {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
}

.active_border {
  border: 4px solid var(--main);
}

.radio_stations_item:hover {
  transform: scale(1.1);
}

.radio_stations_item:hover .expandimg {
  opacity: 1;
}

.radio_stations_item:hover > svg {
  opacity: 1;
}

.radio_stations_item > svg {
  opacity: 0;
  position: absolute;
  right: 5px;
  color: #979ea1;
  transition: all 0.3s ease-in-out;
}

.radio_stations_item .expandimg {
  width: 25px;
  position: absolute;
  bottom: 7px;
  right: 5px;
  filter: opacity(0.6);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.radio_stations_item > svg:hover {
  color: #555;
}

.radio_stations_item_play {
  top: 5px;
  font-size: 36px;
}

.radio_stations_item_zoom {
  bottom: 5px;
  font-size: 32px;
  z-index: 6;
}

.radio_stations_item_favicon {
  font-size: 6px;
  font-size: 32px;
  bottom: 5px;
  left: 5px;
}

.radio_audio {
  position: absolute;
  opacity: 0;
}

.radio_station_item_abs {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  color: #979ea1;
}

.radio_station_item_abs > svg:hover {
  color: #555;
}

.radio_stations_item:hover .radio_station_item_abs {
  opacity: 1;
}

.radio_stations_item_stop {
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 34px;
}

.equalizer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 5px;
  width: 30px;
  height: 20px;
}

.equalizer > div {
  width: 5px;
  background: #616161;
  margin-right: 1px;
}

.equalizer > div:last-child {
  margin-right: 0;
}

.equalizer > div:nth-child(1) {
  animation: bar1 0.5s linear infinite;
  animation-duration: 474ms;
}
.equalizer > div:nth-child(2) {
  animation: bar1 0.5s linear infinite;
  animation-duration: 433ms;
}
.equalizer > div:nth-child(3) {
  animation: bar1 0.5s linear infinite;
  animation-duration: 407ms;
}
.equalizer > div:nth-child(4) {
  animation: bar1 0.5s linear infinite;
  animation-duration: 458ms;
}
.equalizer > div:nth-child(5) {
  animation: bar1 0.5s linear infinite;
  animation-duration: 0.4s;
}

@keyframes bar1 {
  from {
    height: 0%;
  }
  to {
    height: 100%;
  }
}

/* Responsive */

@media (max-width: 1400px) {
  .radio_stations {
    grid-auto-rows: 120px;
    grid-gap: 40px 10px;
  }

  .radio_stations_item .radio_poster {
    width: 100px;
  }

  .radio_stations_item .expandimg {
    width: 20px;
  }

  .radio_stations_item > svg {
    font-size: 26px;
  }

  .radio_stations_item_play {
    font-size: 28px;
  }

  .radio_stations_item_stop {
    font-size: 28px;
  }

  .equalizer {
    width: 20px;
    height: 10px;
  }
}

@media (max-width: 1400px) {
  .radio_stations {
    grid-auto-rows: 110px;
  }

  .radio_stations_item .radio_poster {
    width: 80px;
  }
}

@media (max-width: 1000px) {
  .radio_stations {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 110px;
    grid-gap: 20px;
  }
}

@media (max-width: 800px) {
  .radio_stations {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 600px) {
  .radio_stations {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 450px) {
  .radio_stations {
    grid-template-columns: 100%;
    grid-auto-rows: 130px;
  }

  .radio_stations_item:hover {
    transform: scale(0.95);
  }

  .radio_stations_item > svg {
    opacity: 1;
  }

  .radio_stations_item .expandimg {
    opacity: 1;
  }

  .radio_station_item_abs {
    opacity: 1;
  }

  .radio_wrapper .title {
    font-size: 18px;
  }

  .title > span {
    min-width: 22px;
    height: 22px;
    margin-left: 5px;
    font-size: 14px;
  }

  .radio_wrapper .row .dropdown {
    width: 100px;
  }
}
