@import "../../../../reskin/reskin.css";

.BottomNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 56px;
  background-color: var(--navBackground);
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.32);
  z-index: 20;
}

.Hidden {
  display: none;
}

.BottomNav .bottom-select-container {
  min-width: 128px;
}

.category-select-container {
  margin-left: 8px;
  border: 2px solid #fff;
  border-radius: 4px;
  padding: 3px 6px;
}

.category-select {
  border: none;
  color: #fff;
  font-weight: 500;
  background: transparent;
}

.category-select option {
  color: #000;
  font-weight: 600;
}

.BottomNav svg {
  width: 22px;
  height: 22px;
  color: rgb(173, 173, 173);
}

.BottomNav .icon:hover svg,
.BottomNav .icon:hover p {
  color: #fff;
  transition: 0.3s;
}

.BottomNav .tab-icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px 4px;
  width: 100%;
}

.BottomNav .icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 2px;
  cursor: pointer;
}

.BottomNav .icon img {
  width: 50px;
  position: absolute;
  bottom: 2px;
}

.BottomNav .icon.active svg,
.BottomNav .icon.active .tabName {
  color: #fff;
}

.BottomNav .tabName {
  text-align: center;
  font-size: 11px;
}

.BottomNav .icon p {
  color: rgb(173, 173, 173);
}
