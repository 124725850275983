@import "../../../../reskin/reskin.css";

.LoginModal {
  position: fixed;
  top: 0;
  left: 0;
  /* transform: translate(-50%, -50%); */
  z-index: 200;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(20.54px);
  -webkit-backdrop-filter: blur(20.54px);
  -moz-backdrop-filter: blur(20.54px);
}

.LoginModal .modalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-box-shadow: 0px 0px 19px 7px rgba(0, 0, 0, 0.71);
  box-shadow: 0px 0px 19px 7px rgba(0, 0, 0, 0.71);
  background-color: var(--navBackground);
  height: 450px;
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 21;
  border-radius: 8px;
  padding: 0 30px;
  -webkit-transition: all 1000ms 0s ease-in-out;
  -moz-transition: all 1000ms 0s ease-in-out;
  -ms-transition: all 1000ms 0s ease-in-out;
  transition: all 1000ms 0s ease-in-out;
  -webkit-animation: myfirst 1s;
  animation: myfirst 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  animation-direction: normal;
  -webkit-animation-direction: normal;
}

.LoginModal .closeModal {
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
}

.LoginModal .closeModal svg {
  width: 30px;
  height: 30px;
  color: #fff;
}

.LoginModal h3 {
  color: #fff;
  text-align: center;
  margin-top: 25px;
  font-size: 36px;
}

.LoginModal p {
  color: #fff;
  text-align: center;
  margin-top: 25px;
  font-size: 20px;
}

.LoginModal .modalButtons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
  padding: 20px 0;
}

.LoginModal .modalButtons a {
  text-decoration: none;
  color: #fff;
  background: linear-gradient(var(--main), var(--secondaryGradient)) !important;
  padding: 8px 16px;
  font-size: 18px;
  border-radius: 8px;
}

@-webkit-keyframes myfirst {
  0% {
    top: 0%;
  }
  100% {
    top: 50%;
  }
}
@keyframes myfirst {
  0% {
    top: 0%;
  }
  100% {
    top: 50%;
  }
}
