.news-item-conainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--navBackground);
  overflow-y: hidden;
}

.news-item-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  width: 100%;
  height: 64px;
  background-color: var(--navBackground);
}

.back-arrow-container {
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 64px;
}

.back-arrow-container svg {
  width: 20px;
  height: 20px;
  color: #fff;
}

.news-item-header-logo {
  width: 56px;
  height: 48px;
  object-fit: contain;
}

.news-item-header-border {
  background: var(--navBarMain);
  height: 3px;
  width: 100%;
  position: absolute;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.hero-container {
  position: relative;
  width: 100%;
  height: 232px;
}

.hero-image {
  /* position: absolute; */
  width: 100%;
  height: 232px;
  object-fit: cover;
}

.hero-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8));
}

.hero-title {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 12px 16px;
  font-size: 1.2rem;
  color: #fff;
}

.date-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  width: 100%;
}

.date-info svg {
  width: 20px;
  height: 20px;
  color: #fff;
}

.date {
  margin: 0px 12px;
  color: #fff;
}

.news-item-description {
  padding: 0px 16px 20px;
  height: 100%;
  overflow-y: auto;
}

.news-item-description-text {
  font-size: 1.2rem;
  color: #fff;
}