@import "../../reskin/reskin.css";

.InfoTab {
  position: fixed;
  width: 65%;
  z-index: 1000;
  background: white;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 9px 2px #2b29299e;
  top: 13%;
  padding-bottom: 80px;
  opacity: 0;
  animation: info 2s forwards;
}

.InfoTab img {
  width: 100%;
  opacity: 0;
  animation: info 2s forwards;
}

.InfoTab .buttons {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  display: flex;
  justify-content: center;
  opacity: 0;
  animation: info 2s forwards;
}

.InfoTab .buttons button {
  margin: 0 10px;
  padding: 10px 0;
  width: 200px;
  border: 2px solid var(--main);
  background: white;
  font-size: 20px;
  color: var(--main);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.InfoTab .buttons button:disabled {
  color: gray;
  border: 2px solid gray;
}

.InfoTab .buttons button:hover:disabled {
  background: white;
  color: gray;
  border: 2px solid gray;
}

.InfoTab .buttons button:hover {
  background: var(--main);
  color: white;
}

.InfoTab .buttons button svg {
  font-size: 25px;
}

.InfoTab .close {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background: var(--main);
}

.InfoTab .close svg {
  color: white;
  margin-top: 2px;
  font-size: 30px;
}

@keyframes info {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
