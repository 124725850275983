.search_page .title {
  padding: 30px 0;
}

.search_page .todayEpgs_item_bottom {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.search_page .todayEpgs_item_bottom img {
  width: 70px;
}

.noresult {
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noresult > p {
  width: 100%;
  text-align: center;
  font-size: 34px;
  color: #222;
  font-weight: lighter;
  margin-top: 20px;
}
