.news_screen.now_on_tv_screen .row {
  padding-right: 0;
}

.news_screen .dropdown {
  height: 50px;
  width: 300px;
  font-size: 18px;
}

.news_screen .grid_items_wrapper {
  grid-auto-rows: 350px;
}

.news_screen .news_grid_item {
  width: calc(100% / 3 - 10px);
  height: 350px;
  box-shadow: 0 0 12px 5px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
  position: relative;
  transition: all 0.3s ease-in-out;
  background: #fff;
  overflow: hidden;
}

.news_screen .news_grid_item:nth-child(3n) {
  margin-right: 0;
}

.news_screen .news_grid_item .news_grid_item_top {
  height: 250px;
  background: #323e43;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  justify-content: center;
  cursor: pointer;
}

.news_screen .news_grid_item .news_grid_item_top > img {
  width: 120px;
  transition: all 0.3s ease-in-out;
}

.news_screen .news_grid_item .news_grid_item_bottom {
  height: calc(350px - 250px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 15px;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.news_screen .news_grid_item .epg_desc {
  height: 80px;
  opacity: 0;
  animation: cardopacity 1s linear forwards;
}

.news_screen .news_grid_item:hover {
  width: calc(100% / 2.5 - 10px);
  height: 400px;
}

.news_screen .news_grid_item:hover .news_grid_item_bottom {
  height: calc(350px - 200px);
  justify-content: flex-start;
}

.news_screen .news_grid_item:hover .epg_desc {
  display: flex !important;
  margin-bottom: 15px;
}

@keyframes cardopacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.news_screen .info_popup_img_holder {
  padding-bottom: 10px;
  padding-left: 50px;
}

.news_screen .info_popup_img_holder::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: unset;
  background: rgba(0, 0, 0, 0.5);
}

.news_screen .info_popup_img_holder img {
  opacity: 1;
  width: 20%;
  cursor: unset;
}

.news_screen .info_popup_img_holder h2 {
  font-size: 40px;
  padding-right: 50px;
}

.news_screen .info_popup_body_img_holder {
  justify-content: space-between;
}

.news_screen .info_popup_body_img_holder p {
  font-size: 20px;
  color: #323e43;
  font-weight: 600;
}

.news_screen .info_popup_body .info_popup_text_btn > p {
  padding: 0 50px;
}

@media (max-width: 1600px) {
  .news_screen .dropdown {
    height: 45px;
    width: 250px;
    font-size: 16px;
  }

  .news_screen .info_popup_body .info_popup_text_btn > p {
    padding: 0 30px;
  }
  
  .news_screen .info_popup_body_img_holder p {
    font-size: 16px;
  }

  .news_screen .info_popup_img_holder h2 {
    font-size: 34px;
    padding-right: 30px;
  }

  .news_screen .info_popup_img_holder {
    padding-left: 30px;
  }
}

@media (max-width: 1500px) {
  .news_screen .stream_icon {
    width: 40px;
  }
  .news_screen .epg_desc > p {
    font-size: 14px;
  }

  .news_screen .news_grid_item:hover .news_grid_item_bottom {
    padding-left: 10px;
  }
}
