.modalContent .custom_select {
  width: 100%;
  height: 40px;
  position: relative;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.modalContent .topContent {
  width: 80% !important;
}

.modalContent .custom_select .capital {
  width: 100%;
  height: 40px;
  border: 1px solid #9b9b9b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  border-radius: 4px;
  color: #9b9b9b;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}
.modalContent .custom_select .capital svg {
  transition: all 0.3s ease-in-out;
}
.modalContent .custom_select .capital.rotate svg {
  transform: rotate(-180deg);
}
.modalContent .custom_select .options {
  position: absolute;
  left: 0;
  right: 0;
  top: 37px;
  border: 1px solid #9b9b9b;
  background: #000;
  max-height: 210px;
  border-radius: 0 0 4px 4px;
  overflow-y: scroll;
  z-index: 10;
}
.modalContent .custom_select .options::-webkit-scrollbar {
  width: 0;
}

.modalContent .custom_select p {
  margin: 0 !important;
}
.modalContent .custom_select .options p {
  padding: 10px 15px;
  color: #9b9b9b;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.modalContent .custom_select .options p:hover {
  color: #fff;
}

.modalContent button {
  text-decoration: none;
  color: #fff;
  background: linear-gradient(var(--main), var(--secondaryGradient)) !important;
  padding: 8px 16px;
  font-size: 18px;
  border-radius: 8px;
  border: none;
  margin-bottom: 40px;
}
