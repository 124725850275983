@import "../../../reskin/reskin.css";

.table_wrap {
  margin: 0 auto;
  padding: 50px 0;
  width: 800px;
}

.table_header {
  display: grid;
  grid-template-columns: 100px 1fr 200px;
  padding: 10px 0;
  border-bottom: 3px solid #ccc;
}

.table_header > div {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding-bottom: 5px;
  font-weight: 700;
  border-bottom: 1px solid #ccc;
}

.table_header > div:first-child {
  display: flex;
  align-items: center;
  padding: 0 0 5px 0;
  justify-content: center;
  padding-right: 20px;
}

.table_header > div:last-child {
  justify-content: center;
}

.table_header > div > svg {
  color: #ccc;
  margin-right: 5px;
  cursor: pointer;
}

.table_row {
  display: grid;
  grid-template-columns: 100px 1fr 200px;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.table_row > div {
  padding-left: 20px;
  border-right: 1px solid #ccc;
}

.table_row > div:first-child {
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: center;
}

.table_row > div:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
}

.table_row_button button {
  width: 80%;
  height: 35px;
  background: #fff;
  border: 0;
  border-radius: 5px;
  color: #222;
  font-size: 16px;
}

.table_row_button button:hover {
  background: linear-gradient(var(--main), var(--secondaryGradient)) !important;
  color: #fff !important;
}

@media (max-width: 1000px) {
  .table_wrap {
    width: 95%;
  }
}

@media (max-width: 800px) {
  .table_header {
    grid-template-columns: 50px 1fr 100px;
  }

  .table_row {
    grid-template-columns: 50px 1fr 100px;
  }

  .table_header > div:first-child {
    padding: 0;
    padding-bottom: 5px;
  }

  .table_header > div {
    padding-left: 10px;
  }

  .table_header > div:last-child {
    padding-left: 0;
  }

  .table_row > div {
    padding-left: 5px;
  }

  .table_row_text {
    font-size: 12px;
    word-break: break-all;
    text-align: left;
    width: 100%;
    padding: 0 5px;
  }
}
